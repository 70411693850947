import React, { useContext } from "react";

import Firebase from "./firebase";

export const FirebaseContext = React.createContext(null);

export const withFirebase = Component => props => (
  <FirebaseContext.Consumer>{firebase => <Component {...props} firebase={firebase} />}</FirebaseContext.Consumer>
);

/**
 * Returns a Firebase instance
 * @returns {Firebase}
 */
export const useFirebase = () => {
  return useContext(FirebaseContext);
};

export default Firebase;
