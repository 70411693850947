import React from "react";
import { withRouter } from "react-router-dom";

// Inner wrap the App.js router with this to get debug logging of route changes
class LogRoutes extends React.Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      console.log("Route changed:", location.pathname);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(LogRoutes);
