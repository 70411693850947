import { keyframes } from "styled-components";

export const pulse = keyframes`
  0% {
    background-color: rgba(255, 255, 255, 0.3);
  }
  50% {
    background-color: rgba(255, 255, 255, 0.8);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

export const sessionSlideIn = keyframes`
  from {
    top: 0;
    right: -45%px;
  }

  to {
    top: 0;
    right: 0; 
  }
`;

export const sessionSlideOut = keyframes`
  from {
    top: 0;
    right: 0; 
  }
  to {
    top: 0;
    right: -45%px;
  }
`;

export const LoadingKeyframe = keyframes`
	0% { 
    transform: rotate(0deg);
    color: var(--key-color);
   }
   

   50% {
    color: var(--key-color-2);
    transform: rotate(540deg); 

   }

    100% {
      color: var(--key-color);
      transform: rotate(1080deg); 
    }
`;

export const ChangeColorKeyFrame = keyframes`

  0% { 
    transform: rotate(0deg);
    color: var(--grey-1);;
   }

   20% { 
    color: var(--key-color);
   }

   40% {
    color: var(--key-color-2); 

   }

   70% { 
    color: var(--grey-3);;
   }

    100% {
      color: var(--grey-1);
    }
`;

export const Spinning = keyframes`
	0% { 
    transform: rotate(0deg);
   }

   50% {
    transform: rotate(540deg); 

   }

    100% {
      transform: rotate(1080deg); 
    }
`;

export const navSlideIn = keyframes`
  from {
    left: -500px;
  } 

  to {
    left: 0;
  }
`;

export const buttonRoll = keyframes`
  from {
    opacity: 0
  } 
  to {
    opacity: 1;
    transform: rotate(360deg) translateX(0);
  }
`;

export const smoothIn = keyframes`
  from {
    opacity: 0;
  } 
  to {
    opacity: 1;
  }
`;

export const ScaleIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0)
  } 
  60% {
    opacity: 1;
    transform: scale(1.1)
  } 
  100% {
    opacity: 1;
    transform: scale(1)
  }
`;

export const SlideIn = keyframes`
  0% {
    transform: translate(-680px, 0);
  } 
  100% {
    transform: translate(0, 0);
  }
`;

export const SuggestionSmoothIn = keyframes`
  0% {
    opacity: 0;
    transform: translate(0, -30px);
  } 
  50% {
    opacity: 1;
    transform: translate(0, 5px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const VrHeadsetRoll = keyframes`
  from {
    opacity: 0;
    transform: rotate(0) translateX(0);

  } 
  to {
    opacity: 1;
    transform: rotate(360deg) translateX(0);
  }
`;

export const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const FadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const ToolTipSlideIn = keyframes`
  from {
    opacity: 0;
    right: 100%;
  }
  to {
    opacity: 1;
    right: 8.5%;
  }

`;

export const ButtonPulse = keyframes`
  0% {
    transform: scale(.95);
    box-shadow: 0 0 0 0 rgba(127,101,255, 1);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(127,101,255, 0);
  }
  100% {
    transform: scale(.95);
    box-shadow: 0 0 0 0 rgba(127,101,255, 0);
  }
`;
