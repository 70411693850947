import styled from "styled-components";

export const FooterContainer = styled.footer`
  margin: 40px 0 20px 0;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const FooterInfo = styled.div`
  width: 250px;
  padding: 0px 30px;
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const FooterTitleLink = styled.a`
  color: #7f65ff;
  font-weight: bold;
  font-size: 18px;
`;

export const FooterRegion = styled.p`
  color: #4b4b4b;
  font-size: 12px;
  font-weight: bold;
`;

export const SocialContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 5px;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

export const FooterPrivacyLink = styled.a`
  font-size: 12px;
  margin-top: 12px;
  font-weight: 800;
  color: #4b4b4b;
`;
