import React from "react";
import { useFirebase } from "../Firebase";
import { LogOutStyle } from "./style";
import { LogOutCircle } from "@styled-icons/boxicons-regular/LogOutCircle";
import ReactTooltip from "react-tooltip";

// Show tooltips needs only to be true in desktop navigation
const SignOutButton = ({ history, showTooltip = false }) => {
  const firebase = useFirebase();

  return (
    <LogOutStyle onClick={() => firebase.signOut(history)} data-for="sign-out" data-tip={"<h5>Sign out</h5>"}>
      <LogOutCircle />
      <p>Sign out</p>
      {showTooltip && <ReactTooltip place="right" multiline={true} html={true} id="sign-out"></ReactTooltip>}
    </LogOutStyle>
  );
};

export default SignOutButton;
