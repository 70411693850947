import React from "react";
import styled from "styled-components";
import { LoadingKeyframe } from "../../styles/keyframes";
// import { Spinner6 } from 'styled-icons/icomoon/Spinner6';
import { Spinner10 } from "@styled-icons/icomoon/Spinner10";

const Spinner = styled(Spinner10)`
  width: ${props => (props.width ? props.width : "50px")};
  color: var(--key-color-2);
  animation: ${LoadingKeyframe} 4s infinite ease-in-out;
  background: transparent;
`;

export const Loader = styled.div`
  width: ${props => (props.containerWidth ? props.containerWidth : "100%")};
  height: ${props => (props.containerHeight ? props.containerHeight : "auto")};
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: transparent;

  h2 {
    text-align: center;
    font-size: 20px;
    padding: 5px;
  }
`;

const Loading = ({ containerHeight, containerWidth, width, loadingText }) => (
  <Loader containerHeight={containerHeight} width={width} containerWidth={containerWidth}>
    {loadingText && <h2>{loadingText}</h2>}
    <Spinner width={width} />
  </Loader>
);

export default Loading;
