import styled from "styled-components";

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 550px;
  min-height: 150px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
`;

export const Iframe = styled.iframe`
  border: none;
`;

export const CloseBtn = styled.div`
  text-align: end;
  font-weight: bold;
  margin-bottom: 10px;
  color: #7f65ff;
`;

export const Xbtn = styled.button`
  border: none;
  background-color: white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: #7f65ff;
  font-weight: bold;
`;

export const InputContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
`;
