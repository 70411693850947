import styled from "styled-components";

export const LogOutStyle = styled.button`
  cursor: pointer;
  text-align: center;
  outline: none;
  border: none;
  background: transparent;
  font-weight: bold;
  margin: 20px 0 38px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  svg {
    width: 34px;
    height: auto;
    color: var(--grey-2);

    &:hover {
      color: var(--key-color-2);
      transition: 0.4s;
    }
  }

  p {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  @media (max-width: 768px) {
    margin: 10px 0 0 0;

    svg {
      width: 50px;
      color: var(--grey-3);
    }

    p {
      display: block;
      font-size: 12px;
      color: var(--grey-2);
    }
  }
`;
