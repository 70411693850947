/*
This component supports rendering arbitrary settings from an object. It will use a JSON schema type object to 
understand type and titles, along with a translation object if desired. It also supports enabling/disabling a setting.
A disabled setting instead shows an inherited default setting (e.g from a parent context).

TODO:
Can we show where a default setting comes from, e.g. in the case of scenario settings we inherit both from defaults, user and org.
Can the component be broken into sections, or should that be done by rendering separate components?
How to handle that certain sections or settings are only for admins? E.g. a super admin looking at an organization setting should see more than a regular admin.
Maybe better to handle in separate instance.
*/
import React, { useState, useEffect, useMemo } from "react";
import { isNil, mapValues, isEqual, cloneDeep, toNumber, isNaN, merge } from "lodash";
import settings from "../../constants/settings.json";
import { defaultTranslations, supportedKeys } from "../../constants/defaults";
import { FrLabel, FrInput, FrTextarea } from "../../components/DesignSystem/style";
import { QuestionCircle } from "@styled-icons/fa-solid/QuestionCircle";
import { Edit } from "@styled-icons/boxicons-regular/Edit";
import { DeleteForever } from "@styled-icons/material/DeleteForever";
import ReactTooltip from "react-tooltip";
import CustomSelect from "../../components/CustomSelect";
import { NamedOption } from "../../components/CustomSelect/customSelectOptions";
import { IconButton, SettingsList, Toggle, ScrollableContainer, SettingsContainer, HelpText } from "./style";

const validateValue = (value, schema) => {
  if (!schema) {
    return value;
  }

  switch (schema.type) {
    case "string":
      if (typeof value !== "string") {
        return null;
      }
      if (schema.minLength && value.length < schema.minLength) {
        return null;
      }
      if (schema.maxLength && value.length > schema.maxLength) {
        return null;
      }
      return value;
    case "number":
    case "integer":
      const numberValue = toNumber(value);
      if (isNaN(numberValue)) {
        return null;
      }
      if (schema.minimum !== undefined && numberValue < schema.minimum) {
        return null;
      }
      if (schema.maximum !== undefined && numberValue > schema.maximum) {
        return null;
      }
      if (String(numberValue).length !== String(value).length) {
        return null;
      }
      return numberValue;
    case "boolean":
      if (value === "true" || value === true) {
        return true;
      }
      if (value === "false" || value === false) {
        return false;
      }
      return null;
    case "enum":
      if (schema.enum.includes(value)) {
        return value;
      }
      return null;
    default:
      return value;
  }
};

const getDefaultValue = (newValue, defaultValue, schema) => {
  newValue = newValue ?? defaultValue ?? schema?.default;
  if (isNil(newValue)) {
    // Set a default value based on type
    if (schema.type === "boolean") {
      newValue = false;
    } else if (schema.type === "string") {
      if (schema.enum) {
        newValue = schema.enum[0];
      } else {
        newValue = "";
      }
    } else if (schema.type === "number" || schema.type === "integer") {
      newValue = 0;
    } else if (schema.type === "object") {
      newValue = {};
    }
  }
  return newValue;
};

/**
 *
 * @param {*} props
 * @param {object} props.settingKeys which settings to show in this Editor instance
 * @param {object} props.defaultSettings which default setting values to use for unset values. Can also be used to show where a setting comes from before.
 * @param {object} props.settingsObject The current values of this layer of settings
 * @param {function} props.handleUpdate Callback to update the settingsObject, instead of doing it directly in here
 * @param {object} props.schema The schema to use for this layer of settings
 * @param {object} props.translations The translations to use for this layer of settings
 * @param {boolean} [props.showHelp=true] Whether to show help icons
 * @param {boolean} [props.compact=false] Whether to show a compact version of the editor
 * @param {boolean} [props.editorEditable=true] Whether to allow editing in the editor
 * @returns
 */
const SettingsEditor = ({
  settingKeys,
  defaultSettings,
  settingsObject,
  handleUpdate,
  schema = settings.properties,
  translations = defaultTranslations.settings,
  extraSchema = {},
  extraTranslations = {},
  required = [],
  showHelp = true,
  compact = false,
  editorEditable = true,
  header = "",
  children,
}) => {
  const [formValues, setFormValues] = useState(null);
  const [invalidForms, setInvalidForms] = useState({});
  const [enabledKeys, setEnabledKeys] = useState(null);

  schema = useMemo(() => merge(schema, extraSchema), [schema, extraSchema]);
  translations = useMemo(() => merge(translations, extraTranslations), [translations, extraTranslations]);

  const requiredMap = useMemo(() => required.reduce((obj, key) => ({ ...obj, [key]: true }), {}), [required]);

  const handleChange = target => {
    const key = target.name;
    // target.value comes from form inputs and may be wrong type, invalid, incomplete etc
    // We need to save the value to formValues but only propagate to settings if it's valid
    setFormValues(state => ({ ...state, [key]: target.value }));
    let validatedValue = validateValue(target.value, schema?.[key]);
    setInvalidForms({ ...invalidForms, [key]: validatedValue === null });
    if (handleUpdate && enabledKeys[key] && !isNil(validatedValue) && !isEqual(validatedValue, settingsObject?.[key])) {
      handleUpdate({ ...(settingsObject || {}), [key]: validatedValue });
    }
  };

  const toggleSetting = key => {
    const enabled = !enabledKeys[key];
    setEnabledKeys(old => ({ ...old, [key]: enabled }));
    let newValue;
    if (enabled) {
      newValue = getDefaultValue(formValues?.[key], defaultSettings?.[key], schema?.[key]);
      setFormValues(state => ({ ...state, [key]: newValue }));
    } else {
      newValue = null;
    }

    if (handleUpdate && !isEqual(newValue, settingsObject?.[key])) {
      handleUpdate({ ...settingsObject, [key]: newValue });
    }
  };

  useEffect(() => {
    // TODO If upstream changes we overwrite local values, this could be merged to preserve local changes
    // From start, enable keys that have a value in settings, disable those that don't
    setEnabledKeys(settingKeys.reduce((obj, key) => ({ ...obj, [key]: !isNil(settingsObject?.[key]) }), {}));
    setFormValues(cloneDeep(settingsObject || {}));
  }, [settingKeys, settingsObject]);

  return (
    <SettingsContainer>
      <HelpText>{children}</HelpText>
      <SettingsList compact={compact}>
        {Object.entries(enabledKeys || {}).map(([key, enabled]) => {
          const s = schema[key] || {};
          const title = translations[key]?.title || translations[key]?.name || key;
          const description = translations[key]?.description || "No description available";
          const editable = editorEditable && enabled;
          const value = editable ? formValues?.[key] : defaultSettings?.[key] || schema[key]?.default;
          return (
            <li key={key}>
              <FrLabel padding={"0 10px"} color={editable ? "var(--grey)" : "var(--grey-2)"}>
                {title}
                {showHelp && (
                  <>
                    <QuestionCircle data-for={key} data-tip={description} />
                    <ReactTooltip place="right" effect="solid" id={key} html={true} />
                  </>
                )}
              </FrLabel>

              {s.type === "boolean" && (
                <Toggle
                  type="checkbox"
                  role="switch"
                  checked={value === true}
                  name={key}
                  onChange={e => handleChange({ name: e.target.name, value: e.target.checked })}
                  disabled={!editable}
                />
              )}

              {s.type === "string" && s.enum && (
                <CustomSelect
                  isSearchable={true}
                  isMulti={false}
                  value={value}
                  onChange={e => handleChange({ name: key, value: e.value })}
                  options={s.enum.map(e => ({ value: e, name: translations[key]?.[e]?.title || e }))}
                  option={NamedOption}
                  placeholder={translations[key]?.[value]?.title || value || "Select ..."}
                  disabled={!editable}
                  isDisabled={!editable}
                />
              )}

              {s.type === "string" && !s.enum && s.maxLength > 100 && (
                <FrTextarea
                  type="text"
                  maxLength={s.maxLength}
                  minRows={2}
                  name={key}
                  error={invalidForms[key]}
                  onChange={e => handleChange(e.target)}
                  value={value ?? ""}
                  background={"var(--grey-5)"}
                  disabled={!editable}
                />
              )}

              {s.type === "string" && !s.enum && (!s.maxLength || s.maxLength <= 100) && (
                <FrInput
                  background={"var(--grey-5)"}
                  name={key}
                  type="text"
                  error={invalidForms[key]}
                  min={s.minimum}
                  max={s.maximum}
                  onChange={e => handleChange(e.target)}
                  value={value ?? ""}
                  disabled={!editable}
                />
              )}

              {(s.type === "number" || s.type === "integer") && (
                <FrInput
                  background={"var(--grey-5)"}
                  width={"150px"}
                  name={key}
                  error={invalidForms[key]}
                  type="number"
                  min={s.minimum}
                  max={s.maximum}
                  step={s.type === "integer" ? 1 : 0.1}
                  onChange={e => handleChange({ name: key, value: e.target.value, type: s.type })}
                  value={value ?? 0}
                  disabled={!editable}
                />
              )}

              {s.type === "object" && supportedKeys[key] && (
                <ScrollableContainer>
                  <SettingsEditor
                    settingsObject={value}
                    defaultSettings={defaultSettings[key]}
                    settingKeys={Object.keys(supportedKeys[key] || {})}
                    schema={mapValues(supportedKeys[key], v => ({ ...v, type: "boolean" }))}
                    translations={key === "availableRatings" ? defaultTranslations.evaluation : supportedKeys[key]}
                    showHelp={false}
                    compact={true}
                    handleUpdate={update => handleChange({ name: key, value: update })}
                    editorEditable={editable}
                  />
                </ScrollableContainer>
              )}

              {/* TODO we don't have a fallback case for unknown types */}

              {!requiredMap[key] && editorEditable && (
                <IconButton onClick={() => toggleSetting(key)}>{editable ? <DeleteForever /> : <Edit />}</IconButton>
              )}
            </li>
          );
        })}
      </SettingsList>
    </SettingsContainer>
  );
};

export default SettingsEditor;
