import React from "react";
import Select, { components } from "react-select";
import { Search } from "@styled-icons/boxicons-regular/Search";

const Control = props => {
  return (
    <components.Control {...props}>
      <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
        {props.selectProps.isSearchable ? <Search style={{ width: "18px", margin: "0 0 0 10px" }} /> : null}
        {props.children}
      </div>
    </components.Control>
  );
};

const CustomSelect = props => {
  const customStyles = {
    container: base => ({
      ...base,
      width: "100%",
      maxWith: "100%",
      minWidth: "250px",
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? "#7F65FF !important" : "white",
      color: state.isSelected ? "white !important" : "#7F65FF",
      width: "100%",
      fontSize: 14,
      fontWeight: 600,
      padding: 10,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "var(--key-color-2)",
        color: "white",
      },
      "&:nth-of-type(even)": {
        backgroundColor: "var(--grey-5)",
        "&:hover": {
          backgroundColor: "var(--key-color-2)",
          color: "white",
        },
      },
    }),
    control: (base, state) => ({
      ...base,
      width: "100%",
      opacity: state.isDisabled ? 0.5 : 1,
      marginRight: 5,
      fontSize: 14,
      display: "flex",
      borderColor: state.isFocused ? "var(--key-color-2)" : base.borderColor,
      borderRadius: 20,
      boxShadow: "none",
      "&:hover": {
        borderColor: "var(--key-color-2)",
      },
      "@media (max-width: 768px)": {
        fontSize: 16,
      },
    }),
    placeholder: base => ({
      ...base,
      color: "var(--grey)",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    }),
    dropdownIndicator: () => ({
      cursor: "pointer",
      width: 40,
      color: "7F65FF",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };
  if (props.isMulti) {
    customStyles.valueContainer = base => ({
      ...base,
      width: "300px", // To make multi-select values wrap, not sure about better way to do this
    });
  }
  if (props.error) {
    customStyles.control = base => ({
      ...base,
      borderColor: "var(--status-red)",
      backgroundColor: "rgba(255, 92, 80, 0.15)",
    });
  }

  return (
    <Select
      {...props}
      // @ts-ignore
      inputId={props.id}
      components={{ Control, Option: props.option }}
      isSearchable={props.isSearchable}
      isDisabled={props.isDisabled}
      isMulti={props.isMulti}
      getOptionLabel={props.getOptionLabel}
      getOptionValue={option => option.value || option.id || `${option}`}
      defaultValue={props.defaultValue}
      placeholder={props.placeholder}
      value={props.value}
      onInputChange={inputValue => (inputValue.length <= 25 ? inputValue : inputValue.substr(0, 25))}
      onChange={props.onChange}
      options={props.options}
      styles={customStyles}
    ></Select>
  );
};

export default CustomSelect;
