import { StyledNavListItem } from "../style";

// children is for ReactToolTip on desktop navigation and we need to use the link title as id.
const NavListItem = ({ selected, handleClick, title, image, children }) => {
  return (
    <StyledNavListItem selected={selected} data-for={title} data-tip={`<h5>${title}</h5>`}>
      <button onClick={handleClick}>
        {image}
        <p>{title}</p>
      </button>
      {children}
    </StyledNavListItem>
  );
};

export default NavListItem;
