import styled from "styled-components";

export const AdminContainer = styled.div`
  width: 100%;
  padding-bottom: 60px;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
  }
  @media (max-width: 768px) {
    padding-bottom: 100px;
  }
  h1 {
    text-align: left;
    color: var(--grey);
    font-weight: bold;
  }
`;

export const AdminContentWrapper = styled.div`
  padding: 0 15px 20px;
  width: 80%;
  max-width: 1100px;
  /* min-height: 100vh; */
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  @media (max-width: 768px) {
    width: 95%;
  }
`;

export const AdminTableWrapper = styled.div`
  padding: 20px 0;
  width: 100%;
  min-height: 200px;
  table {
    padding: 5px;
    width: 100%;
  }
`;

export const TableScrollWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const TableHeader = styled.h2`
  width: 100%;
  padding: 0;
  font-weight: bold;
  color: var(--grey);
  display: flex;
  align-items: center;
  margin: ${props => (props.margin ? props.margin : 0)};

  button {
    border: none;
    background: transparent;
    outline: none;
    svg {
      width: 35px;
      color: var(--key-color-2);
      margin: 0 5px;
    }
  }

  span {
    padding-left: 10px;
    font-weight: 500;
  }
`;

export const AdminNavigation = styled.div`
  width: 100%;
  padding: 20px 0;
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;

  h2 {
    padding: 5px 10px;
    font-weight: bold;
    color: var(--grey);
  }
`;

export const NewClientWrapper = styled.div`
  padding: 10px 0;
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: flex-end;
  /* align-content: flex-start;
  justify-content: flex-start; */

  button {
    min-width: 200px;
  }

  input {
    min-width: 250px;
  }

  @media (max-width: 950px) {
    flex-direction: column;
    padding: 10px 15px;
    button {
      width: 50%;
    }
    input {
      width: 100%;
    }
  }
`;

export const VisibilityButton = styled.button`
  padding: 5px 10px;
  background: ${props => (!props.isHidden ? "transparent" : "var(--grey-2)")};
  border: 2px solid var(--grey-2);
  color: ${props => (!props.isHidden ? "var(--grey-2)" : "white")};
  font-weight: bold;
  border-radius: 20px;
  font-size: 12px;
`;

export const ScenarioOrderSelect = styled.div`
  input {
    outline: none;
    font-size: 14px;
    border: 2px soild var(--key-color-2);
    font-weight: bold;
    padding: 5px;
  }
`;

export const SaveScenarioOrder = styled.button`
  background: transparent;
  border: none;
  outline: none;
  font-weight: bold;
  color: var(--key-color-2);
  display: inline;
  font-size: 12px;
  margin-left: 5px;
`;

export const AddScenarioContainer = styled.div`
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;

  @media (max-width: 768px) {
    align-items: baseline;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
`;

export const AddScenarioWrapper = styled.div`
  display: flex;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 500px;

  button {
    margin-left: 5px;
  }

  @media (max-width: 768px) {
    button {
      width: 30%;
      margin: 10px 0 10px 5px;
    }
  }
`;

export const EditClientInput = styled.input`
  padding: 5px 10px;
  outline: none;
  border-radius: 20px;
  border: 2px solid var(--key-color-2);
  font-size: 14px;
  font-weight: 600;
`;

export const PrimeButton = styled.button`
  background: transparent;
  color: ${props => (props.cancel ? "var(--grey-2)" : "var(--key-color-2)")};
  font-size: 14px;
  border: 2px solid;
  border-color: ${props => (props.cancel ? "var(--grey-2)" : "var(--key-color-2)")};
  padding: 5px 15px;
  margin: 0 2px;
  font-weight: bold;
  border-radius: 20px;
`;

export const InnerDataTable = styled.div`
  background: var(--grey-5);
  width: 100%;
  padding: 10px 20px;
  border-radius: 20px;
`;

export const ClickableHeader = styled.h5`
  color: var(--grey);
  transition: 0.4s;
  cursor: pointer;
  text-decoration: underline;

  span {
    font-size: 12px;
    color: var(--grey-2);
    text-decoration: none;
    display: inline-block;
  }

  &:hover {
    transition: 0.4s;
    color: var(--key-color-2);

    span {
      color: var(--grey-2);
    }
  }
`;

export const HeaderLogo = styled.div`
  width: 160px;
  height: 80px;
  margin: 0 5px;
  background: ${props => (props.url ? `url(${props.url})` : "initial")};
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  font-weight: bold;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: var(--grey-2);
  right: 30px;
  position: absolute;
`;

export const LogoDiv = styled.div`
  width: 60px;
  height: 40px;
  margin: 0 5px;
  background: ${props => (props.url ? `url(${props.url})` : "initial")};
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 12px;
  text-align: center;
  white-space: nowrap;
  font-weight: bold;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: var(--grey-2);
`;

export const CallToActionBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 30px 30px 30px 30px;
  background: white;
  border-radius: 20px;
  border: 2px solid var(--key-color-2);
  margin: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);

  @media (max-width: 768px) {
    flex-direction: column;

    button {
      margin: 15px 0 0 0;
    }

    h2 {
      text-align: center;
    }
  }
`;
