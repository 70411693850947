import React, { useMemo } from "react";
import { StyledNavList } from "../style";
import { navigationLinks } from "../../../constants/navigationLinks";
import NavListItem from "./NavListItem";
import ReactTooltip from "react-tooltip";
import { checkManager } from "../../../helpFunctions/auth";

const NavList = ({ showTooltip, currentPath, handleClick, authUser, organization }) => {
  const links = useMemo(() => {
    const isAdmin = checkManager(authUser?.claims, organization?.id); // Not selectedOrg but org saved in profile

    if (isAdmin) {
      return navigationLinks;
    } else {
      return navigationLinks.filter(link => !link.adminOnly);
    }
  }, [authUser?.claims, organization]);

  return (
    <StyledNavList>
      {links.map(link => (
        <NavListItem
          handleClick={() => handleClick(link.href)}
          image={link.image}
          title={link.title}
          selected={currentPath === link.href}
          key={link.title}
        >
          {showTooltip && <ReactTooltip place="right" multiline={true} html={true} id={link.title}></ReactTooltip>}
        </NavListItem>
      ))}
    </StyledNavList>
  );
};

export default NavList;
