import { hasClaimFor, checkAdmin, checkSuperAdmin } from "./auth";
import * as ROUTES from "../constants/routes";
import { isNil, isEmpty } from "lodash";
import TreeModel from "tree-model";

// VISIBILITY STATES
export const VISIBILITY = {
  HIDDEN: "hidden",
  PUBLISHED: "published",
  UNPUBLISHED: "unpublished",
  DIRECT_ACCESS: "directAccess",
  VIEW_ONLY: "viewOnly",
};

const VISIBILITY_ORDER = {
  [VISIBILITY.HIDDEN]: 4,
  [VISIBILITY.PUBLISHED]: 0,
  [VISIBILITY.UNPUBLISHED]: 2,
  [VISIBILITY.DIRECT_ACCESS]: 1,
  [VISIBILITY.VIEW_ONLY]: 3,
};

export function checkHasCompletedScenario(scenario, authUser) {
  if (!authUser?.scenarioUserStats || !scenario?.id) return false;

  const stats = authUser.scenarioUserStats[scenario.id];
  if (!stats) return false;

  return isNil(stats.averageLatestSkill) ? stats.sessionsEnded >= 1 : stats.averageLatestSkill > 0.7;
}

const defaultModeOrder = {
  explain: 0,
  ask: 1,
  answer: 2,
  script: 3,
  chitchat: 4,
  generic: 5,
};

/**
 * Checks if a scenario is a folder. Folders will have childScenarios,
 * but while they are being created they will not yet have children,
 * so we also check if there are no characters and environment.
 * @param {Object} scenario
 * @returns {boolean}
 */
export const isFolder = scenario =>
  scenario?.hasOwnProperty("childScenarios") ||
  Object.keys(scenario?.settings?.characters ?? {}).length === 0 ||
  !scenario?.settings?.environment;

/**
 * Returns all modes from a scenario, sorted by the given or default order
 * @param {object} scenario
 * @param {object} modes
 */
export const getOrderedModes = (scenario, modes) => {
  return Object.entries(scenario?.modes || modes || {})
    .map(([modeId, modeObj]) => {
      // Assign default order if not present
      const order = modeObj.order !== undefined ? modeObj.order : defaultModeOrder[modeId] || 0;
      return { ...modeObj, order, key: modeId };
    })
    .sort((a, b) => a.order - b.order);
};

/**
 * Returns all exchanges from a scenario, sorted by the mode order and the exchange order.
 * @param {object} scenario
 */
export const getOrderedExchanges = scenario => {
  const modes = getOrderedModes(scenario);
  return modes.flatMap(mode =>
    Object.entries(mode.exchanges || {})
      .map(([exchangeId, exchangeObj]) => {
        return { ...exchangeObj, mode: mode.key, id: exchangeId, order: mode.order * 10 + (exchangeObj.order || 0) };
      })
      .sort((a, b) => a.order - b.order)
  );
};

export const getCharacterFromRole = (characters, role) => {
  if (!characters || Object.keys(characters).length === 0) {
    return [];
  } else {
    return Object.keys(characters).filter(charId => characters[charId] && characters[charId].role === role);
  }
};

export const getAvatarRPMUrl = scenarioObj => {
  let botChar = getBotCharacter(scenarioObj);
  if (botChar?.avatar?.endsWith(".glb")) {
    return botChar.avatar;
  }
  return null;
};

export const getBotCharacter = scenarioObj => {
  const bot = getCharacterFromRole(scenarioObj?.settings?.characters, "bot")?.[0];
  return scenarioObj?.settings?.characters?.[bot] || {};
};

export const isSpecialScenario = scenarioId => {
  return !!scenarioId?.match(/(generic-bot-responses-|chitchat-responses-)\w\w-\w\w/);
};

export const scenarioOrganization = scenario => {
  let createdByOrg = scenario.settings?.creatingOrganization || scenario?.id.split("-")[0];
  return createdByOrg;
};

/**
 * Returns scenario IDs a user should have access to, as a sorted list of strings.
 * @param {*} orgSettings
 * @param {*} authUser
 * @returns
 */
export const scenarioIdsWithAccess = (organization, authUser) => {
  let scenarioIds = [];
  let isAdmin = checkAdmin(authUser?.claims, organization.id);
  if (organization?.settings?.availableScenarios) {
    // TODO also give access to special scenarios?
    scenarioIds = Object.entries(organization.settings.availableScenarios)
      .filter(([id, published]) => isAdmin || published)
      .map(([id, published]) => id);
  }
  if (authUser?.scenarioUserStats) {
    scenarioIds = scenarioIds.concat(
      Object.entries(authUser.scenarioUserStats)
        .filter(([id, stats]) => stats.sessionsStarted > 0)
        .map(([id, stats]) => id)
    );
  }
  if (authUser?.claims?.memberOf?.length) {
    scenarioIds = scenarioIds.concat(authUser.claims.memberOf);
  }
  return scenarioIds.sort();
};

export const getScenarioVisibility = (scenarioId, authUser, orgSettings) => {
  let visibility = VISIBILITY.HIDDEN;
  if (orgSettings?.availableScenarios?.[scenarioId] === true) {
    visibility = VISIBILITY.PUBLISHED;
  } else if (orgSettings?.availableScenarios?.[scenarioId] === false) {
    visibility = VISIBILITY.UNPUBLISHED;
  } else if (hasClaimFor(authUser?.claims, null, scenarioId)) {
    // TODO instead of checking claim for null role, check if "player" role?
    visibility = VISIBILITY.DIRECT_ACCESS;
  } else if (authUser?.scenarioUserStats?.[scenarioId]?.sessionsStarted > 0) {
    // The user can see the old scenarios but not play them, as access has been removed
    visibility = VISIBILITY.VIEW_ONLY;
  }
  return visibility;
};

/**
 * Maps an array of scenarios with organization settings and other useful metadata.
 *
 * @param {Object<string, object>} scenarioMap map of scenarios
 * @param {Object} orgSettings settings for the organization
 * @param {Object} authUser authentication information for the user
 * @returns {Array<object>} array of scenarios with metadata
 */
export const scenariosWithMetadata = (scenarioMap, orgSettings, authUser) => {
  if (isEmpty(scenarioMap)) return [];

  let scenarios = Object.keys(scenarioMap).map(key => ({
    id: key,
    ...scenarioMap[key],
  }));

  // Create a map of children ids to all their parents
  const childToParentMap = {};

  const treeModel = new TreeModel({
    modelComparatorFn: (a, b) => {
      return a.order - b.order;
    },
  });
  const root = treeModel.parse({ id: "root", depth: 0 });

  // Traverse a scenario depth first
  function traverse(scenario, topLevelParent, parent) {
    if (!scenario) return;
    if (!topLevelParent) topLevelParent = scenario.id;

    let order = scenario?.order ?? orgSettings?.scenarioOrder?.[scenario.id] ?? Number.MAX_SAFE_INTEGER;

    let node = treeModel.parse({ id: scenario.id, depth: parent.model.depth + 1, order });
    parent.addChild(node);

    for (const childId in scenario.childScenarios || {}) {
      if (!childToParentMap[childId]) {
        childToParentMap[childId] = new Set([topLevelParent]);
      } else {
        childToParentMap[childId].add(topLevelParent);
      }
      if (childId in scenarioMap) {
        traverse(
          { id: childId, ...scenarioMap[childId], order: scenario.childScenarios[childId].order },
          topLevelParent,
          node
        );
      }
    }
  }

  scenarios.forEach(scenario => traverse(scenario, null, root));
  const visibilityMap = {};

  let rv = scenarios.map(scen => {
    // Set visibility, but if we have a top parent we use that to inherit visibility
    let idsGivingAccess = [scen.id, ...Array.from(childToParentMap[scen.id] || [])];
    let visibility = idsGivingAccess
      .map(id => getScenarioVisibility(id, authUser, orgSettings))
      .sort((a, b) => VISIBILITY_ORDER[a] - VISIBILITY_ORDER[b])[0];

    visibilityMap[scen.id] = visibility;

    return {
      ...scen,
      visibility,
      isRootScenario: !childToParentMap[scen.id],
      isFolder: isFolder(scen),
      scenarioOrder: orgSettings?.scenarioOrder?.[scen.id] ?? Number.MAX_SAFE_INTEGER,
      userData: authUser?.scenarioUserStats?.[scen.id] ?? null,
      archived: !!authUser?.scenarioUserStats?.[scen.id]?.archived,
    };
  });

  // Print in depth-first, pre-order, for debugging
  // let out = "";
  // root.walk(n => {
  //   out += `${" ".repeat(Math.max(0, 2 * n.model.depth))}${n.model.id} [${visibilityMap[n.model.id]}]\n`;
  // });
  // console.log(out);

  return rv;
};

export const updateScenarioState = (allScenarios, scenarioId, updateObject) => {
  // Need to return a new copy of the allScenarios array, not mutate existing
  const newScenarios = (allScenarios || []).map(scenario =>
    scenario.id === scenarioId ? { ...scenario, ...updateObject } : scenario
  );
  return newScenarios;
};

export const addScenarioToState = (allScenarios, scenarioId, newScenarioObject, orgSettings, authUser) => {
  if (!scenarioId) {
    throw new Error("Need to set id on the scenario object before saving it to state");
  }
  let scenarioMap = allScenarios.reduce((obj, scen) => {
    obj[scen.id] = scen;
    return obj;
  }, {});
  scenarioMap[scenarioId] = { id: scenarioId, ...newScenarioObject };
  const newScenarios = scenariosWithMetadata(scenarioMap, orgSettings, authUser);
  return newScenarios;
};

export const setOfAllChildScenarios = scenarios => {
  const childScenarios = new Set(
    scenarios.reduce((acc, scen) => {
      if (scen?.childScenarios) {
        acc.push(...Object.keys(scen.childScenarios));
      }

      return acc;
    }, [])
  );

  return childScenarios;
};

export const setOfAllParentScenarios = scenarios => {
  const parentScenarios = new Set(
    scenarios.reduce((acc, scen) => {
      if (scen?.parentScenarios) {
        acc.push(...Object.keys(scen.parentScenarios));
      }

      return acc;
    }, [])
  );

  return parentScenarios;
};

/** Get all parent/grandparent scenarios of a scenario.
 *  @param {Object} parentScenario  @returns {Array<string>} */
export const getAllParentScenarios = (parentScenario, allScenarios) => {
  if (!parentScenario || !parentScenario.id) return [];
  if (!parentScenario.parentScenarios) return [parentScenario.id];
  let rv = [
    ...new Set(
      Object.keys(parentScenario.parentScenarios).flatMap(parentId => [
        parentId,
        ...getAllParentScenarios(
          allScenarios.find(scen => scen.id === parentId),
          allScenarios
        ),
      ])
    ),
  ];
  return rv;
};

export const handlePlayScenario = scenarioId => {
  const win = window.open(`${window.location.origin}${ROUTES.DIALOGUE_APP}/${scenarioId}`, "_blank");
  win?.focus();
};

const defaultScenarioSort = (a, b) => {
  const orderA = isNil(a.scenarioOrder) ? Number.MAX_SAFE_INTEGER : a.scenarioOrder;
  const orderB = isNil(b.scenarioOrder) ? Number.MAX_SAFE_INTEGER : b.scenarioOrder;

  // Get displayName from either settings or directly
  const displayNameA = a.settings ? a.settings.displayName : a.displayName;
  const displayNameB = b.settings ? b.settings.displayName : b.displayName;

  // Compare by scenarioOrder, lower scenarioOrder means higher priority so we want lower first.
  if (orderA < orderB) {
    return -1;
  } else if (orderA > orderB) {
    return 1;
  } else {
    // If scenarioOrders are equal, compare by displayName (ignoring case)
    return displayNameA?.localeCompare(displayNameB, undefined, { sensitivity: "base" });
  }
};

const unplayedFirstSort = (a, b) => {
  if (!a.userData && b.userData) {
    return -1;
  } else if (a.userData && !b.userData) {
    return 1;
  } else {
    return defaultScenarioSort(a, b);
  }
};

const playedMostRecentSort = (a, b) => {
  if (a.userData.lastSessionDateTime > b.userData.lastSessionDateTime) {
    return -1;
  } else {
    return 1;
  }
};

const archivedMostRecentSort = (a, b) => {
  if (a.archived > b.archived) {
    return -1;
  } else {
    return 1;
  }
};

// Widget on /home: visible=true, root scenario, scenarios but additionally sort first by unplayed and cap to 3
// Sort with unplayed first, then scenarioOrder asc, then displayName asc
export const getHomeScenarios = allScenarios => {
  return (allScenarios || [])
    .filter(
      scen =>
        (scen.visibility === VISIBILITY.PUBLISHED || scen.visibility === VISIBILITY.DIRECT_ACCESS) &&
        scen.isRootScenario === true &&
        !scen.archived
    )
    .sort(unplayedFirstSort);
};

// "Visible" on /scenarios
// Sort with scenarioOrder asc, then displayName asc
export const getVisibleScenarios = allScenarios => {
  return (allScenarios || []).filter(scen => scen.visibility !== VISIBILITY.HIDDEN).sort(defaultScenarioSort);
};

// Used by dropdown in scenario editor
export const getOrganizationScenarios = allScenarios => {
  let rv = (allScenarios || [])
    .filter(
      scen =>
        scen.visibility === VISIBILITY.PUBLISHED ||
        scen.visibility === VISIBILITY.UNPUBLISHED ||
        isSpecialScenario(scen.id)
    )
    .sort(defaultScenarioSort);
  return rv;
};

// "Add scenario to organization" dropdown on /admin. The opposite of getOrganizationScenarios
export const getHiddenScenarios = allScenarios => {
  return (allScenarios || [])
    .filter(scen => !(scen.visibility === VISIBILITY.PUBLISHED || scen.visibility === VISIBILITY.UNPUBLISHED))
    .sort(defaultScenarioSort);
};

// "Visible" on /scenarios
export const getPublishedScenarios = allScenarios => {
  return (allScenarios || []).filter(scen => scen.visibility === VISIBILITY.PUBLISHED).sort(defaultScenarioSort);
};

export const getDirectAccessScenarios = allScenarios => {
  return (allScenarios || []).filter(scen => scen.visibility === VISIBILITY.DIRECT_ACCESS).sort(defaultScenarioSort);
};

export const getViewOnlyScenarios = allScenarios => {
  return (allScenarios || []).filter(scen => scen.visibility === VISIBILITY.VIEW_ONLY).sort(defaultScenarioSort);
};

export const getActiveScenarios = allScenarios => {
  return (allScenarios || [])
    .filter(scen => scen.visibility === VISIBILITY.PUBLISHED || scen.visibility === VISIBILITY.DIRECT_ACCESS)
    .sort(defaultScenarioSort);
};

export const getUnpublishedScenarios = allScenarios => {
  return (allScenarios || []).filter(scen => scen.visibility === VISIBILITY.UNPUBLISHED).sort(defaultScenarioSort);
};

// "Published" on /scenarios: visible=true. root scenario
// Sort with scenarioOrder asc, then displayName asc
// Active means both published and personal access
export const getActiveRootScenarios = allScenarios => {
  return (allScenarios || [])
    .filter(
      scen =>
        (scen.visibility === VISIBILITY.PUBLISHED || scen.visibility === VISIBILITY.DIRECT_ACCESS) &&
        scen.isRootScenario === true &&
        !scen.archived
    )
    .sort(defaultScenarioSort);
};

// "Unplayed" on /scenarios: visible=true and additionally filtered by if unplayed
// Sort with scenarioOrder asc, then displayName asc
export const getUnplayedRootScenarios = allScenarios => {
  return (allScenarios || [])
    .filter(
      scen =>
        (scen.visibility === VISIBILITY.PUBLISHED || scen.visibility === VISIBILITY.DIRECT_ACCESS) &&
        scen.isRootScenario === true &&
        !scen.userData &&
        !scen.archived
    )
    .sort(defaultScenarioSort);
};

// "Played" on /scenarios: visible=true and additionally filtered by if played
// Sort with time played (desc)
export const getPlayedRootScenarios = allScenarios => {
  return (allScenarios || [])
    .filter(
      scen =>
        (scen.visibility === VISIBILITY.PUBLISHED ||
          scen.visibility === VISIBILITY.UNPUBLISHED ||
          scen.visibility === VISIBILITY.DIRECT_ACCESS) &&
        scen.isRootScenario === true &&
        scen.userData &&
        !scen.archived
    )
    .sort(playedMostRecentSort);
};

// "Unpublished" on /scenarios:
// Sort with scenarioOrder asc, then displayName asc
export const getUnpublishedRootScenarios = allScenarios => {
  return (allScenarios || [])
    .filter(scen => scen.visibility === VISIBILITY.UNPUBLISHED && scen.isRootScenario === true && !scen.archived)
    .sort(defaultScenarioSort);
};

// Sort with archival time (desc)
export const getArchivedScenarios = allScenarios => {
  return (allScenarios || [])
    .filter(scen => scen.visibility !== VISIBILITY.HIDDEN && scen.archived)
    .sort(archivedMostRecentSort);
};

// Sorted with the scenarioOrder augmented with the order from childScenarios
export const getDirectChildScenarios = (folderScenario, allScenarios, includeUnpublished) => {
  let childScenarios = Object.keys(folderScenario?.childScenarios ?? {})
    .map(childId => {
      return allScenarios.find(scen => scen.id === childId);
    })
    .filter(scen => scen) // Remove scenarios possibly didn't find
    .filter(scen => {
      return (
        scen.visibility === VISIBILITY.PUBLISHED ||
        scen.visibility === VISIBILITY.DIRECT_ACCESS ||
        (includeUnpublished && scen.visibility === VISIBILITY.UNPUBLISHED)
      );
    })
    .sort(
      (a, b) =>
        (folderScenario.childScenarios[a.id]?.order ?? Number.MAX_SAFE_INTEGER) -
        (folderScenario.childScenarios[b.id]?.order ?? Number.MAX_SAFE_INTEGER)
    );
  return childScenarios;
};

// Sort with scenarioOrder asc, then displayName asc
export const getScenariosAddableAsChild = (folderScenario, allScenarios) => {
  let parentScenarios = getAllParentScenarios(folderScenario, allScenarios);
  let addableScenarios = allScenarios
    .filter(
      scenario =>
        (scenario.visibility === VISIBILITY.PUBLISHED || scenario.visibility === VISIBILITY.UNPUBLISHED) &&
        !folderScenario?.childScenarios?.[scenario.id] && // No existing children
        scenario.id !== folderScenario.id && // Not itself
        !parentScenarios.includes(scenario.id) // Not in parent chain
    )
    .sort(defaultScenarioSort);
  return addableScenarios;
};

export const fetchAllChildScenarios = async (childScenarios, scenarioMap, firebase) => {
  try {
    const promises = Array.from(childScenarios).map(async childId => {
      if (!scenarioMap[childId]) {
        const scenario = await firebase.fetchScenario(childId);
        scenarioMap[childId] = scenario;

        if (scenario?.childScenarios) {
          return fetchAllChildScenarios(Object.keys(scenario.childScenarios), scenarioMap, firebase);
        }
      }
    });

    await Promise.all(promises);
  } catch (error) {
    console.error(error);
  }
};

export const canEditScenario = (scenarioId, selectedOrg, authUser) => {
  if (!scenarioId || !authUser || !selectedOrg) return false;
  return (
    checkSuperAdmin(authUser?.claims) ||
    (checkAdmin(authUser?.claims, selectedOrg) && scenarioId.startsWith(selectedOrg + "-"))
  );
};

function canPlayScenario(scenarioId, authUser, orgSettings, isAdmin, isSuperAdmin) {
  let visibility = getScenarioVisibility(scenarioId, authUser, orgSettings);
  return (
    visibility === VISIBILITY.PUBLISHED ||
    visibility === VISIBILITY.DIRECT_ACCESS ||
    (visibility === VISIBILITY.UNPUBLISHED && isAdmin) ||
    isSuperAdmin
  );
}

export const fetchParentsUntilAccess = async (scenarioMap, firebase, authUser, orgSettings) => {
  let isSuperAdmin = checkSuperAdmin(authUser?.claims);
  let isAdmin = checkAdmin(authUser?.claims);

  let canPlay = false;

  // Check current scenarios
  canPlay = Object.keys(scenarioMap).some(id => canPlayScenario(id, authUser, orgSettings, isAdmin, isSuperAdmin));

  if (canPlay) return true;

  let allParents = Array.from(setOfAllParentScenarios(Object.values(scenarioMap || {})));

  // Check parent IDs before fetching to save time
  canPlay = allParents.some(id => canPlayScenario(id, authUser, orgSettings, isAdmin, isSuperAdmin));
  if (canPlay) return true;

  // Fetch all parents to the scenarioMap and recursively re-check all parents
  let parentPromises = allParents
    .filter(parentId => !(parentId in scenarioMap))
    .map(parentId =>
      firebase.fetchScenario(parentId).then(scenario => {
        scenarioMap[parentId] = scenario;
      })
    );
  if (!parentPromises.length) return false; // Nothing more to fetch to check
  await Promise.allSettled(parentPromises);
  return fetchParentsUntilAccess(scenarioMap, firebase, authUser, orgSettings);
};
