import styled from "styled-components";
import { smoothIn } from "../../styles/keyframes";

export const TeamBar = styled.div`
  width: 100%;
  position: sticky;
  background: white;
  z-index: 5;
  top: 0;
  transition: 0.4s;
  padding: 10px 100px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.07);
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 950px) {
    position: static;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    button {
      margin-top: 10px;
    }
  }

  @media (max-width: 450px) {
    padding-top: 45px;
    padding-bottom: 45px;
  }
`;

export const TeamBarContainer = styled.div`
  /* // width: 100%; */
  display: flex;
  align-items: center;
  margin: 0 10px 0 0;

  label {
    margin: 0 10px 0 0;
    min-width: fit-content;
    font-size: 13.5px;
    font-weight: bold;
  }

  button {
    margin: 0 0 0 5px;
  }

  @media (max-width: 950px) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0 0 0 0;

    label {
      margin: 0 0 10px 0;
    }

    button {
      margin: 10px 0 0 0;
    }
  }
`;

export const UserFeedback = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  width: 200px;
  background: white;
  z-index: 100;
  border-radius: 10px;
  bottom: 20px;
  right: 20px;
  min-height: 50px;
  animation: ${smoothIn} 0.6s;
  border: ${props => (props.border ? props.border : "none")};
`;

export const ToastMessageContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
`;

export const ToastMessage = styled.div`
  padding: 5px;
  background: var(--status-yellow);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
  animation: ${smoothIn} 0.4s;
  margin-bottom: 2px;
  margin-top: 2px;

  div {
    display: flex;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    h5 {
      margin: 0 5px;
    }

    svg {
      width: 25px;
    }

    button {
      border-radius: 20px;
      padding: 4px 15px;
      border: none;
      outline: none;
      font-weight: 600;
      color: var(--grey);
      transition: 0.4s;
      &:hover {
        transition: 0.4s;
        color: var(--key-color-2);
      }
    }
  }
`;
