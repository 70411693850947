import { Link } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import { UserInfoContainer } from "../style";
import ReactTooltip from "react-tooltip";
import { Cog } from "@styled-icons/boxicons-solid/Cog";
import { getUserType } from "../../../helpFunctions/auth";

// Show tooltips needs only to be true in desktop navigation
const UserAvatarLink = ({ authUser, showTooltip = false }) => {
  return (
    <Link to={ROUTES.USER_PROFILE}>
      <UserInfoContainer
        data-for="role"
        data-tip={`<h5>${getUserType(authUser?.claims, authUser.organization)}: <span>${authUser?.displayName || "Anonymous"}</span></h5>`}
      >
        {showTooltip && <ReactTooltip place="right" multiline={true} html={true} id="role"></ReactTooltip>}
        <h1>{authUser?.displayName?.charAt(0).toUpperCase() || "U"}</h1>
        <Cog />
      </UserInfoContainer>
    </Link>
  );
};

export default UserAvatarLink;
