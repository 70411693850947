export const LANDING = "/";
export const SIGN_IN = "/signin";
export const SIGN_UP = "/signup";
export const HOME = "/home";
export const ACCOUNT = "/account";
export const SESSIONS = "/sessions";
export const SHARED_SESSION = "/share/sessions/:id";
export const SESSION = "/session";
export const PASSWORD_FORGET = "/pw-forget";
export const SCENARIOS = "/scenarios";
export const SCENARIO = "/scenario";
export const ADMIN = "/admin";
export const SCENARIO_EDITOR = "/admin/scenario-editor";
export const SCENARIO_VIEW = "/scenarios/scenario";
export const ORG_SETTINGS = "/admin/settings";
export const SUPERUSER = "/superuser";
export const DOWNLOADS = "/downloads";
export const HELP = "/help";
export const UPLOAD = "/upload";
export const DIALOGUE_APP = "/play";
export const USER_PROFILE = "/user-profile";
