import React from "react";
import { InstagramWithCircle } from "@styled-icons/entypo-social/InstagramWithCircle";
import { FacebookWithCircle } from "@styled-icons/entypo-social/FacebookWithCircle";
import { LinkedinWithCircle } from "@styled-icons/entypo-social/LinkedinWithCircle";
import { PRIVACY_POLICY } from "../../constants/urls";
import {
  FooterContainer,
  FooterInfo,
  FooterPrivacyLink,
  FooterRegion,
  FooterTitleLink,
  SocialContainer,
} from "./style";

function Footer() {
  return (
    <FooterContainer>
      <FooterInfo>
        <FooterRegion>Delivered by</FooterRegion>
        <FooterTitleLink href="https://www.fictivereality.com/" target="_blank" rel="noreferrer">
          Fictive Reality
        </FooterTitleLink>
        <FooterRegion>Stockholm, Sweden</FooterRegion>
        <SocialContainer>
          <a href="https://www.instagram.com/fictive.reality/" target="_blank" rel="noreferrer">
            <span className="sr-only">Link to Fictive Realitys Instagram page</span>
            <InstagramWithCircle style={{ width: "20px", color: "rgba(127, 101, 255, 0.69)" }} />
          </a>
          <a href="https://www.facebook.com/profile.php?id=100037288485968" target="_blank" rel="noreferrer">
            <span className="sr-only">Link to Fictive Realitys Facebook page</span>
            <FacebookWithCircle style={{ width: "20px", color: "rgba(127, 101, 255, 0.69)" }} />{" "}
          </a>
          <a href="https://www.linkedin.com/company/fictive-reality/" target="_blank" rel="noreferrer">
            <span className="sr-only">Link to Fictive Realitys Linkedin page</span>
            <LinkedinWithCircle style={{ width: "20px", color: "rgba(127, 101, 255, 0.69)" }} />
          </a>
        </SocialContainer>
      </FooterInfo>
      <FooterPrivacyLink href={PRIVACY_POLICY} target="_blank" rel="noreferrer">
        Privacy Policy
      </FooterPrivacyLink>
    </FooterContainer>
  );
}

export default Footer;
